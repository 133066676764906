import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { ProductFilterMapper } from '../ProductFilterMapper';
import { ProductSearchResults, SearchOptions } from '../ProductModel';
import { ProductSearchResultsMapper } from '../ProductSearchResultsMapper';
import { ProductSearchService } from '../ProductSearchService';
import { AlgoliaProductFilterMapper } from './AlgoliaProductFilterMapper';
import { AlgoliaAutocomplete, AlgoliaProduct, SORTING_INDICES } from './AlgoliaProductModel';
import { AlgoliaProductSearchResults, AlgoliaProductSearchResultsMapper } from './AlgoliaProductSearchResultsMapper';
import { AlgoliaAutocompleteResultsMapper, AlgoliaAutocompleteSearchResults } from './AlgoliaAutocompleteResultsMapper';
import { AutocompleteResultsMapper } from '../AutocompleteResultsMapper';

export type AlgoliaProductSearchServiceParameters = { appId: string; apiKey: string };

export class AlgoliaProductSearchService implements ProductSearchService {
    client: SearchClient;
    productFilterMapper: ProductFilterMapper;
    productSearchResultsMapper: ProductSearchResultsMapper;
    autocompleteResultsMapper: AutocompleteResultsMapper;

    constructor(options: AlgoliaProductSearchServiceParameters) {
        const { apiKey, appId } = options;
        this.client = algoliasearch(appId, apiKey);
        this.productFilterMapper = new AlgoliaProductFilterMapper();
        this.productSearchResultsMapper = new AlgoliaProductSearchResultsMapper();
        this.autocompleteResultsMapper = new AlgoliaAutocompleteResultsMapper();
    }

    async search(options: SearchOptions): Promise<ProductSearchResults> {
        const { keyWords = '', filters, pagination, sorting = 'relevance', language = 'en' } = options;
        const { page = 1 } = pagination ?? {};

        const index = this.client.initIndex(SORTING_INDICES[sorting]);
        const algoliaProductFilters = filters ? this.productFilterMapper.map(filters) : undefined;
        const searchResponse = await index.search<AlgoliaProduct>(keyWords, {
            page: page - 1 ?? 0,
            hitsPerPage: pagination?.productsPerPage ?? 20,
            filters: algoliaProductFilters?.filters,
        });
        const result: AlgoliaProductSearchResults = {
            searchResponse,
        };
        return this.productSearchResultsMapper.map(result, language);
    }

    async autocomplete(keywords: string) {
        if (!keywords) return undefined;

        const index = this.client.initIndex('products_query_suggestions');

        const searchResponse = await index.search<AlgoliaAutocomplete>(keywords, { page: 0, hitsPerPage: 5 });

        const result: AlgoliaAutocompleteSearchResults = {
            searchResponse,
        };

        return this.autocompleteResultsMapper.map(result);
    }
}

//
